(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["psin"] = factory();
	else
		root["Easybroadcast"] = root["Easybroadcast"] || {}, root["Easybroadcast"]["psin"] = factory();
})(window, function() {
return 