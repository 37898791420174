import * as platform from "platform"
import {manager} from "../protos/eb.manager"
import { Subject, Observable } from "rxjs"
import { IStatsInputPlugin } from "../statsCollectors"


enum StatsPlayerState {
  PLAYING = 1,
  IDLE,
  BUFFERING,
  PAUSED,
  STOP,
}

class PlayerStatsInputPlugin implements IStatsInputPlugin {
  private origin: string = window.origin || ""
  private content: string
  private protocol: string
  private bufferLength: number = -1
  private state: manager.PlayingState = manager.PlayingState.IDLE
  private lastPlayingState: manager.PlayingState = manager.PlayingState.IDLE
  private rebuffers: number = 0
  private reBufferingTime: number = 0
  private lastRebufferingTime: number
  private playingTime: number = 0
  private playingTimeTimer: number
  private startTime: number
  private sendTimer: number
  private os: string
  private osMobileString: string
  private browserMobileString: string
  private browser: string
  private eventInfo: Subject<manager.Message>
  private isAudio: boolean = false
  private isOnDemand: boolean = false
  private bandwidthFluctuation: number = 0
  private bandwidth: number
  private timeshift: number = 0
  private lastTimeshift: number = 0
  private lastBandwidth: number
  public constructor() {
    this.eventInfo = new Subject<manager.Message>()
  }

  public subscribeEvents = (): Subject<manager.Message> => {
    return this.eventInfo
  }
  public start = (): PlayerStatsInputPlugin => {
    if (this.sendTimer) {
        window.clearInterval(this.sendTimer)
      }
    this.sendTimer = window.setInterval(
      () => {
        this.sendPlayerInfo()
        this.sendPeerInfo()
        this.sendPeerState()
      },
      300,
    )
    return this
  }
  public stop = (): void => {
    clearInterval(this.sendTimer)
  }
  public getInitTime = (): number => {
    return Date.now()
  }

  public reset = (): void => {
      // reset the contents after sending
  }

  public setOrigin = (origin: string): void => {
    this.origin = origin
  }

  public setMobileOS = (os: string): void => {
    // android: `and_${platform.os.version}`
    // "and_9" || "and_8" || "and_7" || "and_6" || "and_5" || "and_4.4" || "and_4.x" || "and_old"
    // iOS: `ios_${platform.os.version}`
    // "ios_12.3" || "ios_12.1.2" || ... || "ios_old"
    this.osMobileString = os
  }

  public setMobileBrowser = (browser: string): void => {
    // android: `appandroid_${version}`
    // ios: `appios_${version}`
    this.browserMobileString = browser
  }

  public setContent = (content: string): void => {
    this.content = content
  }

  public setProtocol = (protocol: string): void => {
    this.protocol = protocol
  }

  public setBufferLength = (bufferLength: number): void => {
    this.bufferLength = bufferLength
  }

  public setTimeshift = (timeshift: number): void => {
    this.lastTimeshift = this.timeshift
    this.timeshift = timeshift
    if (timeshift !== this.lastTimeshift) {
      this.sendPlayerState()
    }
  }


  public setState = (state: manager.PlayingState): void => {
    // Handling RebufferingTime
    let action
    this.state = this.lastPlayingState
    if (state === manager.PlayingState.PAUSED) {
      action = manager.ArgumentsEvent.Actions.PAUSE
    } else if (state === manager.PlayingState.PLAYING) {
      action = manager.ArgumentsEvent.Actions.PLAY
    } else if (state === manager.PlayingState.STOP) {
      action = manager.ArgumentsEvent.Actions.STOP
    } else {
      action = manager.ArgumentsEvent.Actions.REBUFFER
    }
    if ((state === manager.PlayingState.BUFFERING) &&
    (this.state !== manager.PlayingState.PAUSED)) {
        this.lastRebufferingTime = Date.now()
        console.log("Rebuffering 1",this.lastRebufferingTime)
    } else if (
        (state !== manager.PlayingState.PAUSED) &&
        (this.state === manager.PlayingState.BUFFERING) &&
        (typeof this.lastRebufferingTime === "number")
    )  {
        this.reBufferingTime += ((Date.now() - this.lastRebufferingTime) / 1000)
        console.log("RebufferingTime", this.reBufferingTime)
        this.lastRebufferingTime = undefined
    }
    // handling Playing Time
    if (
        (state === manager.PlayingState.BUFFERING) ||
        (state === manager.PlayingState.PLAYING)
    )  {
        if (this.playingTimeTimer !== undefined) {
            window.clearInterval(this.playingTimeTimer)
        }
        this.playingTimeTimer = window.setInterval(
            () => {this.playingTime = this.playingTime + 1
            },
            1000,
        )
    } else {
        if (this.playingTimeTimer !== undefined) {
            window.clearInterval(this.playingTimeTimer)
        }
    }
    this.state = state
    // Event to send Player State Change
    if (state !== this.lastPlayingState) {
      // this.eventInfo.next(new manager.Message({
      //     Type: manager.MessageType.MessageTypeEvent,
      //     ArgumentsEvent: {
      //         EventName: manager.ArgumentsEvent.EventClass.PLAYER,
      //         Action: action,
      //     },
      // }))
      this.sendPlayerState()
    }
  }
  public setStartTime = (startTime: number): void => {
    this.startTime = startTime
  }

  public incRebuffer = (): void => {
    this.rebuffers = this.rebuffers + 1
  }

  public setBandwidthFluctuation = (): void => {
    this.bandwidthFluctuation++
  }

  public setBandwidth = (bandwidth: number): void => {
    this.lastBandwidth = this.bandwidth
    if (bandwidth !== this.lastBandwidth) {
      // this.eventInfo.next(new manager.Message({
      //     Type: manager.MessageType.MessageTypeEvent,
      //     ArgumentsEvent: {
      //         EventName: manager.ArgumentsEvent.EventClass.PLAYER,
      //         Action: manager.ArgumentsEvent.Actions.FLUCTUATE,
      //     },
      // }))
      this.sendPlayerState()
    }
    this.bandwidth = bandwidth
  }

  private getOS = (): string => {
    switch (platform.os.family) {
      case "Windows":
      case "Windows XP":
        this.os = this.getOSWindows()
        break
      case "OS X":
        this.os = this.getOSOSX()
        break
      case "Linux":
      case "Ubuntu":
      case "Debian":
      case "Fedora":
      case "Red Hat":
      case "SuSE":
        this.os = this.getOSLinux()
        break
      case "Android":
        this.os = this.getOSAndroid()
        break
      case "iOS":
        this.os = this.getOSiOS()
        break
      case "Windows Phone":
        this.os = this.getOSWp()
        break
      default:
        if ((/windows/i).test(platform.os.family)) {
          this.os = this.getOSWindows()
          // miss a break here
          break
        }
        this.os = "NA"
    }
    return this.os
  }

  /**
   * Guess windows version.
   */
  private getOSWindows = (): string => {
    switch (platform.os.version) {
      case "10.0":
      case "10":
        return "w_10"
      default:
    }
    switch (platform.os.family) {
      case "Windows NT 10.0":
      case "Windows NT 10":
      case "Windows NT 6.3":
        return "w_10"
      case "Windows NT 6.2":
        return "w_8"
      case "Windows NT 6.1":
        return "w_7"
      case "Windows XP":
        return "w_xp"
      default:
        return "w_old"
    }
  }
    /**
     * Guess OSX version.
     */
  private getOSOSX = (): string => {
    if ((typeof platform.os.version === "string") && platform.os.version.length) {
      return `m_${platform.os.version}`
    }
    return "m_old"
  }

  /**
   * Guess Linux version.
   */
  private getOSLinux = (): string => {
    let distro: string = platform.os.family
    if (distro === "Linux") {
      return "l_other"
    }
    distro = distro.substr(0, 49).toLowerCase()
    return `l_${distro}`
  }
  /**
   * Guess Android version.
   */
  private getOSAndroid = (): string => {
    if ((typeof platform.os.version === "string") && platform.os.version.length) {
      return `and_${platform.os.version}`
    }
    return "and_old"
  }
  /**
   * Guess iOS version.
   */
  private getOSiOS = (): string => {
    if ((typeof platform.os.version === "string") && platform.os.version.length) {
      return `ios_${platform.os.version}`
    }
    return "ios_old"
  }
  /**
   * Guess Windows Phone version.
   */
  private getOSWp = (): string => {
    if ((typeof platform.os.version === "string") && platform.os.version.length) {
      return `wp_${platform.os.version}`
    }
    return "wp_old"
  }
  /**
   * Guess browser.
   */
  private getBrowser = (): string => {
    let short: string

    switch (platform.name) {
      case "Chrome":
        short = "chr"
        break
      case "Firefox":
      case "Firefox for iOS":
        short = "ff"
        break
      case "IE":
        short = "ie"
        break
      case "Microsoft Edge":
        short = "e"
        break
      case "Safari":
        short = "s"
        break
      case "Opera Mini":
      case "Opera":
        short = "o"
        break
      default:
        this.browser = "na"
        return
    }
    const res: RegExpExecArray = /^(\d+).*/.exec(platform.version)

    if (res === null) {
      this.browser = `${short}_na`
    }
    this.browser = `${short}_${res[1]}`
    return this.browser
  }
  private sendPeerInfo = (): void => {
    this.eventInfo.next(new manager.Message({
        Type: manager.MessageType.MessageTypeEvent,
        ArgumentsEvent: {
            EventName: manager.ArgumentsEvent.EventClass.PEER,
            Action: manager.ArgumentsEvent.Actions.INFO,
            ArgumentsPeerInfo: {
              Radio: this.isAudio,
              VOD: this.isOnDemand,
              OS: this.osMobileString || this.getOS(),
              Browser: this.browserMobileString || this.getBrowser(),
              StreamProto: this.protocol,
              StartUpTime: this.startTime,
              Origin: this.origin,
              Content: this.content,
              Timezone: new Date().getTimezoneOffset(),
            },
        },
    }))
  }
  private sendPlayerInfo = (): void => {
      this.eventInfo.next(new manager.Message({
        Type: manager.MessageType.MessageTypeEvent,
        ArgumentsEvent: {
            EventName: manager.ArgumentsEvent.EventClass.PLAYER,
            Action: manager.ArgumentsEvent.Actions.INFO,
            ArgumentsPlayerInfo: {
              PlayingState: this.state,
              WatchingTime: this.playingTime,
              BufferLength: this.bufferLength || -1,
              Rebuffers: this.rebuffers,
              RebufferingTime: this.reBufferingTime,
              BandwidthFluctuation: this.bandwidthFluctuation,
              Bandwidth: this.bandwidth,
            },
        },
    }))
  }
  private sendPlayerState = () => {
    this.eventInfo.next(new manager.Message({
      Type: manager.MessageType.MessageTypeEvent,
      Timestamp: Date.now(),
      ArgumentsEvent: {
        EventName: manager.ArgumentsEvent.EventClass.PLAYER,
        Action: manager.ArgumentsEvent.Actions.STATE,
        ArgumentsPlayerState: {
          PlayingState: this.state,
          Quality: this.bandwidth,
          Timeshift: this.timeshift,
        },
      },
    }))
  }
  private sendPeerState = () => {
    this.eventInfo.next(new manager.Message({
      Type: manager.MessageType.MessageTypeEvent,
      Timestamp: Date.now(),
      ArgumentsEvent: {
        EventName: manager.ArgumentsEvent.EventClass.PEER,
        Action: manager.ArgumentsEvent.Actions.STATE,
        ArgumentsPeerState: {
          WatchingTimeSec: this.playingTime,
          BufferLengthBytes: this.bufferLength,
          Rebuffers: this.rebuffers,
          RebufferingTime: this.reBufferingTime,
        },
      },
    }))
  }
}
export {
  IStatsInputPlugin,
  PlayerStatsInputPlugin,
  StatsPlayerState,
}
